<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-sheet elevation="1" class="pa-10 sheet rounded-lg mb-10">
          <div class="header d-flex mb-2">
            <div class="text-h4">单位</div>
            <v-spacer></v-spacer>
            <v-btn color="success" @click="addUnit">
              <v-icon class="mr-2">mdi-plus</v-icon>
              新增
            </v-btn>
          </div>
          <v-divider class="mb-2"></v-divider>
          <empty tips="暂无单位，可点击右上方按钮添加~" v-if="units.length === 0"></empty>
          <template v-else>
            <v-row class="my-4">
              <unit-card
                v-for="unit in units"
                :key="unit.id"
                :unit="unit"
                @edit="editUnit"
              ></unit-card>
            </v-row>
          </template>
        </v-sheet>
      </v-col>
    </v-row>
    <edit-unit v-model="dialog" :id="id"></edit-unit>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import UnitCard from 'components/mod/UnitCard.vue'
import EditUnit from 'components/mod/EditUnit.vue'

export default {
  components: {
    UnitCard,
    EditUnit
  },
  data () {
    return {
      dialog: false,
      id: null
    }
  },
  computed: {
    ...mapState('mod', ['units'])
  },
  methods: {
    addUnit () {
      this.dialog = true
      this.id = null
    },
    editUnit (id) {
      this.dialog = true
      this.id = id
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  position: relative;
  .add {
    position: absolute;
    right: 16px;
    bottom: -50%;
  }
}
</style>
