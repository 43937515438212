<template>
  <v-dialog
    v-model="dialog"
    width="700"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="currentList"
        :label="$attrs.title"
        :disabled="$attrs.disabled"
        outlined
        readonly
      >
        <template v-slot:append>
          <v-btn
            color="red lighten-2"
            class="mt-n2"
            v-bind="attrs"
            v-on="on"
            :disabled="$attrs.disabled"
            dark
          >
            编辑
          </v-btn>
        </template>
      </v-text-field>
    </template>
    <v-card>
      <v-card-text>
        <skill-list v-bind="$attrs" v-on="$listeners"></skill-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import SkillList from './SkillList.vue'

export default {
  components: {
    SkillList
  },
  model: {
    prop: 'list',
    event: 'change'
  },
  data () {
    return {
      dialog: false
    }
  },
  computed: {
    currentList () {
      return this.$attrs.list.map(key => {
        const name = this.$attrs.dbMap[key]?.label || key
        return name
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
