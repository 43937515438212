<template>
  <div>
    <v-list dense>
      <v-subheader>{{ title }}</v-subheader>
      <template v-if="list.length">
        <v-list-item
          v-for="(item, i) in currentList"
          :key="i"
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.label }}</v-list-item-title>
          </v-list-item-content>
          <!-- TODO: 数据支持参数的形式，但暂未表现出来 -->
          <v-list-item-icon>
            <v-btn color="error" plain @click="remove(i)">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-list-item-icon>
        </v-list-item>
      </template>
      <empty v-else :tips="tips"></empty>
    </v-list>
    <v-row>
      <v-col>
        <v-autocomplete
          v-model="inputText"
          :items="dbMapList"
          item-text="label"
          item-value="value"
        >
          <template v-slot:append-outer>
            <v-icon @click="onConfirm" color="primary">mdi-upload</v-icon>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { remove } from 'lodash-es'

export default {
  model: {
    prop: 'list',
    event: 'change'
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    tips: {
      type: String,
      default: '暂无内容'
    },
    list: {
      type: Array,
      default () {
        return []
      }
    },
    // 数据库中的 map 数据
    dbMap: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      inputText: ''
    }
  },
  computed: {
    // ...mapGetters('mod', ['technicalUnitAbilities', 'unitSpells', 'unitAbilities']),
    // 技能表
    // allAbilities () {
    //   return {
    //     header1: { header: '天赋' },
    //     ...this.unitAbilities,
    //     header2: { header: '科技天赋' },
    //     ...this.technicalUnitAbilities,
    //     header3: { header: '法术' },
    //     ...this.unitSpells
    //   }
    // },
    // 技能列表
    dbMapList () {
      return Object.values(this.dbMap)
    },
    // 用于规范化要显示的列表数据
    currentList () {
      return this.list.map(key => {
        return this.dbMap[key]
      })
    }
  },
  methods: {
    remove (index) {
      const list = remove(this.list, (_n, i) => {
        return i !== index
      })
      this.$emit('change', list)
    },
    onConfirm () {
      console.log(this.inputText)
      this.$emit('change', [
        ...this.list,
        this.inputText
      ])
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
