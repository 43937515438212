<template>
  <v-dialog :value="value" @input="onClose" persistent fullscreen max-width="600px" transition="dialog-bottom-transition">
    <v-card>
      <!-- 顶栏 -->
      <v-toolbar
        dark
        color="rgb(83, 109, 254)"
      >
        <v-btn
          icon
          dark
          @click="onClose"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ modeText }}单位</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            dark
            text
            @click="submit"
          >
            保存
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="mt-8">
        <v-form ref="form">
          <v-row>
            <v-col cols="4">
              <!-- 名称 -->
              <v-text-field
                v-model="form.name"
                :rules="rules.name"
                label="单位名称"
                dense
                outlined
                required
              ></v-text-field>
              <!-- 名称（升级后） -->
              <v-text-field
                v-model="form.plusName"
                :rules="[v => isNeutral ? true : (!!v || '单位名称必填')]"
                label="单位名称（升级后）"
                dense
                outlined
                required
                :disabled="isNeutral"
              ></v-text-field>
              <!-- 图标 -->
              <v-file-input
                @change="onFileChange"
                label="单位图片"
                accept="image/png"
                prepend-icon=""
                append-icon="mdi-image"
                hide-details
                outlined
                dense
              ></v-file-input>
              <div class="d-flex my-2">
                <v-spacer></v-spacer>
                <tips-text text="*单位图片是一个精灵图，包含了 20 张图像，**24x24**为一帧，所以总尺寸是**H24 W480**像素，1-4 帧为空闲动作，5-8 帧为行走动作，9-12 帧是攻击动作，13-16 帧是受伤动作，17-20 帧是死亡动作"></tips-text>
              </div>
              <div class="d-flex justify-center my-2">
                <v-img
                  :src="form.image"
                  :aspect-ratio="1"
                  width="100%"
                  height="100px"
                  class="indigo lighten-5 flex-grow-0"
                  contain
                ></v-img>
              </div>
              <!-- 升级后的图片 -->
              <v-file-input
                @change="onFileChange($event, true)"
                label="单位图片（升级后）"
                accept="image/png"
                prepend-icon=""
                append-icon="mdi-image"
                hide-details
                outlined
                dense
              ></v-file-input>
              <div class="d-flex justify-center my-2">
                <v-img
                  :src="form.plusImage"
                  :aspect-ratio="1"
                  width="100%"
                  height="100px"
                  class="indigo lighten-5 flex-grow-0"
                  contain
                ></v-img>
              </div>
              <!-- TODO: 暂时不支持修改声效 -->
              <!-- 攻击类型 -->
              <v-select
                class="mb-4"
                v-model="form.attackType"
                :items="attackTypeList"
                label="攻击类型"
                item-text="label"
                item-value="value"
                hide-details
                dense
                outlined
              ></v-select>
              <!-- 生物类型 -->
              <v-select
                v-model="form.living"
                :items="livingList"
                label="生物类型"
                item-text="label"
                item-value="value"
                hide-details
                dense
                outlined
              ></v-select>
              <div class="d-flex my-2">
                <v-spacer></v-spacer>
                <tips-text text="*受**转化类与召唤类**技能的影响"></tips-text>
              </div>
              <!-- 链接生物 -->
              <v-select
                v-model="form.link"
                :items="unitList"
                label="链接生物"
                item-text="label"
                item-value="value"
                dense
                hide-details
                outlined
              ></v-select>
              <div class="d-flex my-2">
                <v-spacer></v-spacer>
                <tips-text text="***保镖**与**召唤**携带的单位（无相关技能时可不填）"></tips-text>
              </div>
              <!-- 分组 -->
              <v-select
                v-model="form.unitGroups"
                :items="unitGroupList"
                :rules="rules.group"
                label="分组"
                item-text="label"
                item-value="value"
                multiple
                dense
                outlined
                chips
                deletable-chips
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-switch
                v-model="isNeutral"
                color="primary"
                :label="`${isNeutral ? '中立' : '阵营'}生物`"
              ></v-switch>
              <!-- 金币花费 -->
              <v-select
                v-model="form.goldCost"
                :items="goldCostList"
                :rules="rules.goldCost"
                label="金币花费"
                item-text="label"
                item-value="value"
                hide-details
                dense
                outlined
              ></v-select>
              <div class="d-flex my-2">
                <v-spacer></v-spacer>
                <div class="text-caption">
                  *基础属性：{{ $mod.GoldCost[form.goldCost].desc }}
                </div>
              </div>
              <!-- 平衡系数 -->
              <v-text-field
                v-model="form.balanceModifier"
                :rules="rules.balance"
                label="平衡系数(%)"
                type="number"
                append-icon="mdi-percent"
                dense
                outlined
                required
              ></v-text-field>
              <v-text-field
                v-model="form.plusBalanceModifier"
                :rules="[v => isNeutral ? true : (!!v && parseInt(v) > 0) ? true : '平衡系数必须是大于 0 的整数']"
                label="平衡系数(%)（升级后）"
                type="number"
                append-icon="mdi-percent"
                :disabled="isNeutral"
                hide-details
                dense
                outlined
                required
              ></v-text-field>
              <div class="d-flex my-2">
                <v-spacer></v-spacer>
                <div class="text-caption">
                  *实际属性是基础属性的
                  <label class="font-weight-black">{{ form.balanceModifier || 0 }}%</label>
                  （不确定）
                </div>
              </div>
              <!-- 每周刷新数 -->
              <v-text-field
                v-model="form.weeklyGrowth"
                :rules="[v => isNeutral ? true : (!!v && parseInt(v) > 0) ? true : '每周刷新数必须是大于 0 的整数']"
                label="每周刷新数"
                type="number"
                :disabled="isNeutral"
                dense
                outlined
                required
              ></v-text-field>
              <!-- 稀有资源类型 -->
              <v-select
                class="mb-4"
                v-model="form.rareResourceType"
                :items="resourceTypeList"
                :disabled="isNeutral"
                label="稀有资源类型"
                item-text="label"
                item-value="value"
                dense
                outlined
              ></v-select>
              <!-- 稀有资源数量 -->
              <v-text-field
                v-model="form.rareResourceCount"
                :rules="[v => isNeutral ? true : (!!v && parseInt(v) >= 0) ? true : '稀有资源数量必须是大于等于 0 的整数']"
                label="稀有资源数量"
                type="number"
                :disabled="isNeutral"
                dense
                outlined
                required
              ></v-text-field>
              <div class="d-flex my-2">
                <v-spacer></v-spacer>
                <tips-text text="稀有资源数量为 **0** 时，表示该单位只需要金币就能招募"></tips-text>
              </div>
            </v-col>
            <v-col cols="4">
              <!-- 天赋列表 -->
              <skill-dialog
                v-model="form.spells"
                title="法术列表"
                tips="暂无法术"
                :dbMap="unitSpells"
              ></skill-dialog>
              <skill-dialog
                v-model="form.plusSpells"
                title="法术列表（升级后）"
                tips="暂无法术"
                :dbMap="unitSpells"
                :disabled="isNeutral"
              ></skill-dialog>
              <!-- 天赋列表 -->
              <skill-dialog
                v-model="form.abilities"
                title="天赋列表"
                tips="暂无天赋"
                :dbMap="unitAbilities"
              ></skill-dialog>
              <skill-dialog
                v-model="form.plusAbilities"
                title="天赋列表（升级后）"
                tips="暂无天赋"
                :dbMap="unitAbilities"
                :disabled="isNeutral"
              ></skill-dialog>
              <!-- 科技天赋列表 -->
              <skill-dialog
                v-model="form.techAbilities"
                title="科技天赋列表"
                tips="暂无科技天赋"
                :dbMap="technicalUnitAbilities"
              ></skill-dialog>
              <skill-dialog
                v-model="form.techPlusAbilities"
                title="科技天赋列表（升级后）"
                tips="暂无科技天赋"
                :dbMap="technicalUnitAbilities"
                :disabled="isNeutral"
              ></skill-dialog>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { nanoid } from 'nanoid'
import { mapGetters, mapMutations, mapState } from 'vuex'
import MixinModAssets from 'mixins/MixinModAssets'
import { fileToBase64 } from 'utils/index'
import SkillDialog from './SkillDialog.vue'
import TipsText from './TipsText.vue'

function getDefaultForm () {
  return {
    name: '',
    plusName: '',
    image: '',
    plusImage: '',
    goldCost: 75,
    weeklyGrowth: 30,
    rareResourceCount: 0,
    rareResourceType: 'O',
    balanceModifier: 100,
    plusBalanceModifier: 100,
    attackType: 1,
    living: 2,
    link: 'Panza',
    unitGroups: ['Alliance of the Frontier'],
    spells: [],
    plusSpells: [],
    abilities: [],
    plusAbilities: [],
    techAbilities: [],
    techPlusAbilities: [],
    sound: 'Imp'
  }
}

const rules = {
  name: [
    v => !!v || '单位名称必填'
  ],
  goldCost: [
    v => !!v || '金币花费必选'
  ],
  balance: [
    v => (!!v && parseInt(v) > 0) ? true : '平衡系数必须是大于 0 的整数'
  ],
  size: [
    v => (!!v && parseInt(v)) ? v : '加成等级必填'
  ],
  effect: [
    v => !!v || '加成特效必选'
  ],
  living: [
    v => !!v || '链接生物必填'
  ],
  group: [
    v => !!v.length || '分组必选'
  ]
}

export default {
  mixins: [MixinModAssets],
  components: {
    SkillDialog,
    TipsText
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    id: {
      type: String
    }
  },
  data () {
    return {
      rules,
      form: getDefaultForm(),
      // currentAbility: 'Flying',
      isNeutral: true
    }
  },
  computed: {
    ...mapState('mod', ['units']),
    ...mapGetters('mod', ['technicalUnitAbilities', 'unitSpells', 'unitAbilities', 'unitGroupings', 'allUnits', 'resourceTypes']),
    isEdit () {
      return this.id !== null
    },
    modeText () {
      return this.isEdit ? '编辑' : '创建'
    },
    goldCostList () {
      return Object.values(this.$mod.GoldCost)
    },
    attackTypeList () {
      return Object.values(this.$mod.AttackType)
    },
    livingList () {
      return Object.values(this.$mod.Living)
    },
    unitGroupList () {
      return Object.values(this.unitGroupings)
    },
    unitList () {
      return Object.values(this.allUnits)
    },
    resourceTypeList () {
      return Object.values(this.resourceTypes)
    }
  },
  watch: {
    id () {
      this.updateForm()
    }
  },
  methods: {
    ...mapMutations('mod', ['addUnit', 'editUnit']),
    isSkillsEmpty () {
      const skillNames = ['abilities', 'spells', 'techAbilities']
      const plusSkillNames = ['plusAbilities', 'plusSpells', 'techPlusAbilities']
      const skillDatas = skillNames.map(name => this.form[name])
      const plusSkillDatas = plusSkillNames.map(name => this.form[name])
      if (this.isNeutral) {
        return skillDatas.every(skill => skill.length === 0)
      }
      return skillDatas.every(skill => skill.length === 0) || plusSkillDatas.every(skill => skill.length === 0)
    },
    submit () {
      const isValid = this.$refs.form.validate()
      if (!isValid) {
        return
      }
      if (!this.form.image) {
        this.$noti.error('请提供单位图标')
        return
      }
      if (this.isNeutral && !this.form.plusName) {
        this.$noti.error('请提供单位图标（升级后）')
        return
      }
      if (this.isSkillsEmpty()) {
        this.$noti.error('请添加单位天赋及法术')
        return
      }
      const unit = { ...this.form, isNeutral: this.isNeutral }
      // 为新增的数据添加 id
      if (!unit.id) {
        unit.id = nanoid()
      }
      // 根据模式选择 mutation
      const mutation = this.isEdit ? this.editUnit : this.addUnit
      mutation({
        id: unit.id,
        unit
      })
      this.$noti.success(`${this.modeText}成功`)
      this.onClose()
    },
    updateForm () {
      if (!this.isEdit) {
        this.form = getDefaultForm()
        return
      }
      const item = this.units.find(item => item.id === this.id)
      this.form = {
        ...item
      }
    },
    onFileChange (file, isPlus) {
      fileToBase64(file).then(base64 => {
        const property = isPlus ? 'plusImage' : 'image'
        this.form[property] = base64
      })
    },
    onClose () {
      this.$emit('input', false)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
