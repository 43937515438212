<template>
  <v-col lg="4" md="12" xs="12" cols="12">
    <v-card class="card">
      <v-img
        :src="unit.image"
        height="100px"
        class="indigo lighten-5 flex-grow-0"
        contain
      ></v-img>
      <v-card-title>
        <v-chip
          :color="attackTypeColors[unit.attackType]"
          class="mr-2"
          dark
          small
        >{{ attackType.label }}</v-chip>
        {{ unit.name }}
      </v-card-title>

      <v-card-text class="pb-0">
        <!-- 生物类型 -->
        <div class="mb-2">
          <label class="font-weight-bold">{{ living.label }}</label>
          <label class="font-weight-regular" v-if="unit.link"> (链接生物为 {{ unit.link }})</label>
        </div>
        <!-- 花费与平衡 -->
        <div>{{ goldCost.label }} | 平衡系数 {{ unit.balanceModifier }}%</div>
        <div class="text-caption">
          <label class="font-weight-bold">{{ unit.balanceModifier }}% </label>
          <label class="text-decoration-underline">({{ goldCost.desc }})</label>
        </div>
      </v-card-text>

      <v-card-text>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
            >具有 {{ abilities.length }} 个技能</div>
          </template>
          <template>
            <div v-for="(ab, index) in abilities" :key="index">
              {{ ab.label }}
            </div>
          </template>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
            >属于 {{ unitGroups.length }} 个分组</div>
          </template>
          <template>
            <div v-for="(g, index) in unitGroups" :key="index">
              {{ g.label }}
            </div>
          </template>
        </v-tooltip>
      </v-card-text>

      <v-card-actions class="d-flex">
        <v-btn color="primary" depressed outlined small @click="onEdit">编辑</v-btn>
        <!-- TODO: 删除弹窗 -->
        <v-btn color="error" depressed outlined small @click="onDelete">删除</v-btn>
      </v-card-actions>
    </v-card>
  </v-col>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import MixinModAssets from 'mixins/MixinModAssets'

const attackTypeColors = {
  0: 'brown',
  1: 'red darken-2',
  2: 'blue darken-1'
}

export default {
  mixins: [MixinModAssets],
  props: {
    unit: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('mod', ['technicalUnitAbilities', 'unitSpells', 'unitAbilities', 'unitGroupings', 'units']),
    goldCost () {
      return this.$mod.GoldCost[this.unit.goldCost]
    },
    attackType () {
      return this.$mod.AttackType[this.unit.attackType]
    },
    living () {
      return this.$mod.Living[this.unit.living]
    },
    allAbilities () {
      return {
        ...this.technicalUnitAbilities,
        ...this.unitSpells,
        ...this.unitAbilities
      }
    },
    abilities () {
      return this.unit.abilities.map(ab => this.allAbilities[ab])
    },
    unitGroups () {
      return this.unit.unitGroups.map(g => this.unitGroupings[g])
    },
    attackTypeColors () {
      return attackTypeColors
    }
  },
  methods: {
    ...mapMutations('mod', ['deleteUnit']),
    onEdit () {
      this.$emit('edit', this.unit.id)
    },
    onDelete () {
      this.deleteUnit({
        id: this.unit.id
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
